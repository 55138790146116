<template>
    <div class="mb-4">
        <label class="flex my-2">
            <div class="bg-white rounded border-accor w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <input type="checkbox" v-model="first" class="opacity-0 absolute">
                <svg class="hidden w-3 h-3 pointer-events-none" fill="#2c7a7b" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
            </div>
            <div v-if="this.$root.currentLanguage == 'en' ||
                        this.$root.currentLanguage == 'ko' ||
                        this.$root.currentLanguage == 'cn' ||
                        this.$root.currentLanguage == 'nl' ||
                        this.$root.currentLanguage == 'ar' ||
                        this.$root.currentLanguage == 'il' ||
                        this.$root.currentLanguage == 'kz' ||
                        this.$root.currentLanguage == 'hr' ||
                        this.$root.currentLanguage == 'az' ||
                        this.$root.currentLanguage == 'ua' ||
                        this.$root.currentLanguage == 'ge' ||
                        this.$root.currentLanguage == 'cs' ||
                        this.$root.currentLanguage == 'es' ||
                        this.$root.currentLanguage == 'gr' ||
                        this.$root.currentLanguage == 'tat' ||
                        this.$root.currentLanguage == 'al' ||
                        this.$root.currentLanguage == 'lv' ||
                        this.$root.currentLanguage == 'tr' ||
                        this.$root.currentLanguage == 'ba' ||
                        this.$root.currentLanguage == 'fa' ||
                        this.$root.currentLanguage == 'ro' ||
                        this.$root.currentLanguage == 'hu' ||
                        this.$root.currentLanguage == 'sk' ||
                        this.$root.currentLanguage == 'et' ||
                        this.$root.currentLanguage == 'ky'"
                    class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                By ordering service you agree with the terms and conditions
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">of the Public offer</a>

                <!--and with
                <a class="text-accor" @click="$modal.show('modal-privacy-policy')" href="javascript:void(0)">the Privacy policy</a>

                for the pupose of your personal data processing-->

                (By clicking «Order» you automatically agree with
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">the Public offer</a>
                ).

            </div>
            <!-- de -->
            <div v-if="this.$root.currentLanguage == 'de'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                Mit der Bestellung des Dienstes akzeptieren Sie die Allgemeinen Geschäftsbedingungen
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">des öffentlichen Angebots</a>

                (durch Klicken auf „Bestellen“ akzeptieren Sie automatisch
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">das öffentliche Angebot</a>
                ).
            </div>
            <!-- it -->
            <div v-if="this.$root.currentLanguage == 'it'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                Ordinando il servizio si accettano i termini e le condizioni
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">dell'offerta al pubblico</a>

                (Cliccando su "Ordinare" si accetta automaticamente

                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">l'offerta al pubblico</a>
                ).
            </div>
            <!-- fr -->
            <div v-if="this.$root.currentLanguage == 'fr'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                En commandant le service, vous acceptez les termes et conditions
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">de l'offre publique</a>

                (En cliquant sur "Commander", vous acceptez automatiquement
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">l'offre publique</a>
                ).
            </div>
            <!-- ru -->
            <div v-if="this.$root.currentLanguage == 'ru'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                Заказывая услугу, вы соглашаетесь с условиями и положениями
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">Публичной оферты</a>

                (Нажав кнопку «Заказать», вы автоматически соглашаетесь с
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">Публичной офертой</a>
                ).
            </div>
            <!-- pl -->
            <div v-if="this.$root.currentLanguage == 'pl'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                Zamawiający usługę wyraża zgodę na warunki
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">oferty publicznej</a>

                (Klikając "Zamów" automatycznie wyrażasz zgodę na
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">ofertę publiczną</a>
                ).
            </div>
            <!-- pt -->
            <div v-if="this.$root.currentLanguage == 'pt'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">
                Ao solicitar o serviço, concorda com os termos e condições
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">da Oferta Pública</a>
                (Ao clicar em «Pedir», concorda automaticamente com a
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">Oferta Pública</a>
                ).
            </div>
            <!-- uz -->
            <div v-if="this.$root.currentLanguage == 'uz'" class="select-none text-xs leading-tight font-light" :class=" (!first && checkBoxError) ? 'text-red-500':''">

                Bu xizmatni buyurtma bergandan keyin,
                <a class="text-accor"  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')" href="javascript:void(0)">siz Ommaviy oferta</a>

                shartlari va istaklari bilan rozigingizni avtomatik tarzda tasdiqlaysiz.

            </div>

        </label>

        <label class="flex my-2">
            <div class="bg-white rounded border-accor w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <input type="checkbox" v-model="second" class="opacity-0 absolute">
                <svg class="hidden w-3 h-3 pointer-events-none" fill="#2c7a7b" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
            </div>
            <div v-if="this.$root.currentLanguage == 'en' ||
                        this.$root.currentLanguage == 'ko' ||
                        this.$root.currentLanguage == 'cn' ||
                        this.$root.currentLanguage == 'nl' ||
                        this.$root.currentLanguage == 'ar' ||
                        this.$root.currentLanguage == 'il' ||
                        this.$root.currentLanguage == 'kz' ||
                        this.$root.currentLanguage == 'hr' ||
                        this.$root.currentLanguage == 'az' ||
                        this.$root.currentLanguage == 'ua' ||
                        this.$root.currentLanguage == 'ge' ||
                        this.$root.currentLanguage == 'cs' ||
                        this.$root.currentLanguage == 'es' ||
                        this.$root.currentLanguage == 'gr' ||
                        this.$root.currentLanguage == 'tat' ||
                        this.$root.currentLanguage == 'al' ||
                        this.$root.currentLanguage == 'lv' ||
                        this.$root.currentLanguage == 'tr' ||
                        this.$root.currentLanguage == 'ba' ||
                        this.$root.currentLanguage == 'fa' ||
                        this.$root.currentLanguage == 'ro' ||
                        this.$root.currentLanguage == 'hu' ||
                        this.$root.currentLanguage == 'sk' ||
                        this.$root.currentLanguage == 'et' ||
                        this.$root.currentLanguage == 'ky'"
                class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                I read and accepted
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">the Privacy policy</a>

                for the purpose of your personal data processing <!-- and the terms of the
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Personal data consent clause</a>

                I read and acknowledged the terms of
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Personal data processing consent</a>-->
            </div>

            <!-- de -->
            <div v-if="this.$root.currentLanguage == 'de'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Ich habe die
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">Datenschutzerklärung</a>

                für die Verarbeitung Ihrer personenbezogenen Daten gelesen und akzeptiere sie.<!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>

            <!-- it -->
            <div v-if="this.$root.currentLanguage == 'it'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Ho letto e accetto

                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">l'informativa sulla privacy</a>

                per il trattamento dei dati personali. <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>

            <!-- fr -->
            <div v-if="this.$root.currentLanguage == 'fr'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                J'ai lu et accepté
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">confidentialité</a>

                aux fins du traitement de vos données personnelles <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>

            <!-- ru -->
            <div v-if="this.$root.currentLanguage == 'ru'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Я прочитал и принял
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">Политику конфиденциальности</a>

                в целях обработки ваших персональных данных <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>

            <!-- pl -->
            <div v-if="this.$root.currentLanguage == 'pl'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Przeczytałam/em i akceptuję
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">politykę prywatności </a>

                w zakresie przetwarzania danych osobowych. <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>
            <!-- pt -->
            <div v-if="this.$root.currentLanguage == 'pt'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Li e aceitei a
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">Política de Privacidade</a>
                para fins de processamento dos seus dados pessoais
                <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>

            <!-- uz -->
            <div v-if="this.$root.currentLanguage == 'uz'" class="select-none text-xs leading-tight font-light" :class="(!second && checkBoxError) ? 'text-red-500':''">
                Men
                <a class="text-accor" @click="$modal.show('modal-privacy-policy'); $root.getPublicInformation('privacy_policy')" href="javascript:void(0)">Maxfiylik siyosatini</a>

                o’qib chiqib roziligimni tasdiqlayman. <!--и в соответствии с условиями
                <a class="text-accor" @click="$modal.show('modal-personal-data')" href="javascript:void(0)">Пункта о согласии на персональные данные</a>
                -->
            </div>
        </label>


        <div v-if="this.$root.currentLanguage == 'en' ||
                    this.$root.currentLanguage == 'ko' ||
                    this.$root.currentLanguage == 'cn' ||
                    this.$root.currentLanguage == 'nl' ||
                    this.$root.currentLanguage == 'ar' ||
                    this.$root.currentLanguage == 'il' ||
                    this.$root.currentLanguage == 'kz' ||
                    this.$root.currentLanguage == 'hr' ||
                    this.$root.currentLanguage == 'az' ||
                    this.$root.currentLanguage == 'ua' ||
                    this.$root.currentLanguage == 'ge' ||
                    this.$root.currentLanguage == 'cs' ||
                    this.$root.currentLanguage == 'es' ||
                    this.$root.currentLanguage == 'gr' ||
                    this.$root.currentLanguage == 'tat' ||
                    this.$root.currentLanguage == 'al' ||
                    this.$root.currentLanguage == 'lv' ||
                    this.$root.currentLanguage == 'tr' ||
                    this.$root.currentLanguage == 'ba' ||
                    this.$root.currentLanguage == 'fa' ||
                    this.$root.currentLanguage == 'ro' ||
                    this.$root.currentLanguage == 'hu' ||
                    this.$root.currentLanguage == 'sk' ||
                    this.$root.currentLanguage == 'et' ||
                    this.$root.currentLanguage == 'ky'"
            class="text-gray-800 text-xs  font-light">
            You may withdraw or change your consent at any time by e-mailling
            <a class="text-accor" href="mailto:privacy@dgstay.com" v-if="$root.hotelCode !== 'alphasirius'">privacy@dgstay.com</a>        
            <a class="text-accor" href="mailto:privacy@2roomz.com" v-else>privacy@2roomz.com</a>            
        </div>
        <div v-if="this.$root.currentLanguage == 'de'" class="text-gray-800 text-xs  font-light">
            Sie können Ihre Einwilligung jederzeit widerrufen oder ändern, indem Sie eine E-Mail an
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a> senden
            <!--
            <a class="text-accor" href="mailto:privacy@2roomz.com">privacy@2roomz.com</a>
             -->
        </div>

        <div v-if="this.$root.currentLanguage == 'it'" class="text-gray-800 text-xs  font-light">
            Può revocare o modificare il Suo consenso in qualsiasi momento inviando un'e-mail a
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a>
            <!--
            <a class="text-accor" href="mailto:privacy@2roomz.com">privacy@2roomz.com</a>
             -->
        </div>

         <div v-if="this.$root.currentLanguage == 'fr'" class="text-gray-800 text-xs  font-light">
            Vous pouvez retirer ou modifier votre consentement à tout moment en envoyant un e-mail
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a>
            <!--
            <a class="text-accor" href="mailto:privacy@2roomz.com">privacy@2roomz.com</a>
             -->
        </div>

        <div v-if="this.$root.currentLanguage == 'ru'" class="text-gray-800 text-xs  font-light">
            Вы можете отозвать или изменить свое согласие в любое время по электронной почте.
            <a class="text-accor" href="mailto:privacy@dgstay.com" v-if="$root.hotelCode !== 'alphasirius'">privacy@dgstay.com</a>        
            <a class="text-accor" href="mailto:privacy@2roomz.com" v-else>privacy@2roomz.com</a>            
        </div>

        <div v-if="this.$root.currentLanguage == 'pl'" class="text-gray-800 text-xs  font-light">
            Zgodę można w każdej chwili wycofać lub zmienić za pomocą wiadomości e-mail
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a>
            <!--
            <a class="text-accor" href="mailto:privacy@2roomz.com">privacy@2roomz.com</a>
             -->
        </div>
        <div v-if="this.$root.currentLanguage == 'pt'" class="text-gray-800 text-xs  font-light">
            Poderá retirar ou alterar o seu consentimento a qualquer momento enviando um email para
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a>
            <!--
            <a class="text-accor" href="mailto:privacy@2roomz.com">privacy@2roomz.com</a>
             -->
        </div>
        <div v-if="this.$root.currentLanguage == 'uz'" class="text-gray-800 text-xs  font-light">
            Istalqan vaqtda Siz o'z roziligingizni quydagi electron manzilga yozib bekor qilishingiz mumkin.
            <a class="text-accor" href="mailto:privacy@dgstay.com">privacy@dgstay.com</a>
        </div>

        <modal name="modal-personal-data" :width="'100%'" :height="'100%'" scrollable>
            <div class="flex py-4 px-4 shadow text items-center">
                <button @click="$modal.hide('modal-personal-data')"><svg xmlns='http://www.w3.org/2000/svg' class='w-8 h-8 fill-current' viewBox='0 0 512 512'><title>Arrow Back</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/></svg></button>
                <div v-if="this.$root.currentLanguage == 'en' ||
                            this.$root.currentLanguage == 'ko' ||
                            this.$root.currentLanguage == 'cn' ||
                            this.$root.currentLanguage == 'pl' ||
                            this.$root.currentLanguage == 'nl' ||
                            this.$root.currentLanguage == 'de' ||
                            this.$root.currentLanguage == 'ar' ||
                            this.$root.currentLanguage == 'il' ||
                            this.$root.currentLanguage == 'kz' ||
                            this.$root.currentLanguage == 'fr' ||
                            this.$root.currentLanguage == 'hr' ||
                            this.$root.currentLanguage == 'az' ||
                            this.$root.currentLanguage == 'ua' ||
                            this.$root.currentLanguage == 'pt' ||
                            this.$root.currentLanguage == 'ge' ||
                            this.$root.currentLanguage == 'cs' ||
                            this.$root.currentLanguage == 'es' ||
                            this.$root.currentLanguage == 'it' ||
                            this.$root.currentLanguage == 'gr' ||
                            this.$root.currentLanguage == 'tat'||
                            this.$root.currentLanguage == 'uz'||
                            this.$root.currentLanguage == 'al' ||
                            this.$root.currentLanguage == 'lv' ||
                            this.$root.currentLanguage == 'tr' ||
                            this.$root.currentLanguage == 'ba' ||
                            this.$root.currentLanguage == 'fa' ||
                            this.$root.currentLanguage == 'ro' ||
                            this.$root.currentLanguage == 'hu' ||
                            this.$root.currentLanguage == 'sk' ||
                            this.$root.currentLanguage == 'et' ||
                            this.$root.currentLanguage == 'ky'"
                    class="ml-2">Data protection</div>
                <div v-if="this.$root.currentLanguage == 'ru'" class="ml-2">Защита данных</div>
            </div>
            <div class="p-4 text-xs h-screen overflow-y-auto">
                <div v-if="this.$root.currentLanguage == 'en' ||
                            this.$root.currentLanguage == 'ru' ||
                            this.$root.currentLanguage == 'ko' ||
                            this.$root.currentLanguage == 'cn' ||
                            this.$root.currentLanguage == 'pl' ||
                            this.$root.currentLanguage == 'nl' ||
                            this.$root.currentLanguage == 'de' ||
                            this.$root.currentLanguage == 'ar' ||
                            this.$root.currentLanguage == 'il' ||
                            this.$root.currentLanguage == 'kz' ||
                            this.$root.currentLanguage == 'fr' ||
                            this.$root.currentLanguage == 'hr' ||
                            this.$root.currentLanguage == 'az' ||
                            this.$root.currentLanguage == 'ua' ||
                            this.$root.currentLanguage == 'pt' ||
                            this.$root.currentLanguage == 'ge' ||
                            this.$root.currentLanguage == 'cs' ||
                            this.$root.currentLanguage == 'es' ||
                            this.$root.currentLanguage == 'it' ||
                            this.$root.currentLanguage == 'gr' ||
                            this.$root.currentLanguage == 'tat' ||
                            this.$root.currentLanguage == 'al' ||
                            this.$root.currentLanguage == 'lv' ||
                            this.$root.currentLanguage == 'tr' ||
                            this.$root.currentLanguage == 'ba' ||
                            this.$root.currentLanguage == 'fa' ||
                            this.$root.currentLanguage == 'ro' ||
                            this.$root.currentLanguage == 'hu' ||
                            this.$root.currentLanguage == 'sk' ||
                            this.$root.currentLanguage == 'et' ||
                            this.$root.currentLanguage == 'ky'"
                    class="p-2"  style="margin-bottom: 100px;">
                    <div class="text-lg font-bold pb-2">Privacy notice, 14 June 2021</div>
                    <p class="my-3">
                        Clip Style, LLC is registered in the Russian Federation and complies with personal data protection law of your home country being a legal entity acting worldwide.
                    </p>
                    <p class="my-3">
                        Clip Style, LLC is an owner of the 2ROOMZ hotel service web-platform located at the domain 2roomz.com. As a data processor, we will collect and process your personal information in the European Union, and any country within the European Union where we operate with our partner's hotels.
                    </p>
                    <p class="my-3">
                        We process your personal data for efficient hotel services rendering only and for the purpose of your unique experience with hotel products.
                    </p>
                    <p class="my-3">
                        We analyze your accommodation preferences for further customization of our products and services. We do not use cookie files as such and any other files of the same kind since we do not need them for the service rendering.
                    </p>
                    <p class="my-3">
                        We do not use your personal data for marketing purposes and do not send you ads.
                    </p>
                    <p class="my-3">
                        We will share your personal data with third parties that we work with for the purposes of service provision. Such sharing will be made only through safety encrypted channels. Third parties are listed in chapter 4 of the Privacy policy.
                    </p>
                    <p class="my-3 flex">
                        You may withdraw your consent at any time by sending an email to <a href="mailto:privacy@2roomz.com" class="text-blue-600 mx-1">privacy@2roomz.com.</a>
                    </p>
                    <p class="my-3">
                        Further details about our use of your personal data, your right of access and correction, the complaints process, etc. can be found in our Privacy Policy.
                    </p>
                    <p class="my-3 flex">
                        You may obtain a relevant copy of our Privacy Policy at any time at our website: <a href="https://2roomz.com/" class="text-blue-600 mx-1">www.2roomz.com.</a>
                    </p>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    props: ['checkAgreement','checkBoxError'],

    data() {
        return {
            first: false,
            second: false,
        }
    },

    watch: {
        first() {
            this.$emit('update:checkAgreement', this.first && this.second);
        },

        second() {
            this.$emit('update:checkAgreement', this.first && this.second);
        }
    }
}
</script>
<style>
    .border-accor {
        border: 1px solid #2c7a7b;
    }

    .text-accor {
        color: #2c7a7b;
    }

    input:checked + svg {
        display: block;
    }
</style>